import {ReactElement, forwardRef} from 'react';
import {BaseButtonProps} from '../base-button';
import {VariantProps} from '@stitches/react';
import {Button} from './index.styled';

type ButtonIconProps = Omit<BaseButtonProps, 'uppercase' | 'children'> & {
	/**
	 * Button state. During the value apply the appropriate styles
	 */
	isActive: boolean;

	/** Content. Can only be svg element */
	children: ReactElement<React.SVGProps<SVGSVGElement>>;
} & VariantProps<typeof Button>;

export const ButtonToggleIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
	({dataCy, css = {}, skeleton, disabled, isActive, ...props}, forwaredRef) => {
		return (
			<Button
				data-cy={dataCy ?? 'ui-kit-button-toggle-icon'}
				ref={forwaredRef}
				css={css}
				skeleton={skeleton}
				disabled={skeleton || disabled}
				isActive={isActive}
				{...props}
			/>
		);
	},
);
