import {Draggable, DraggableId} from 'react-beautiful-dnd';
import {DragDrop} from '@esgi/ui/icons';
import {SelectableListItemProps} from '../selectable-list-item';
import useResizeObserver from '@react-hook/resize-observer';
import {PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import {buttonDraggableHeight} from './constants';
import {BoxStub, ButtonDraggable, ItemContent, ItemDraggable} from './index.styled';

export type SelectableListItemDraggableProps = Omit<SelectableListItemProps, 'children'> &
	PropsWithChildren & {
		/** Required item index for rearrange. */
		index: number;

		/** Required id used for rearrange. */
		draggableId: DraggableId;
	};

export function SelectableListItemDraggable({
	dataCy = 'ui-kit-selectable-list-item-draggable',
	index,
	draggableId,
	children,
	forceSelect = false,
	withActiveBackground = false,
	...props
}: SelectableListItemDraggableProps) {
	const [contentHeight, setContentHeight] = useState(0);
	const contentRef = useRef<HTMLDivElement>(null);

	useResizeObserver(contentRef, (entry) => setContentHeight(entry.contentRect.height));

	useLayoutEffect(() => {
		setContentHeight(contentRef.current?.clientHeight ?? 0);
	}, []);

	return (
		/**
		 * Keep value draggableId and key the same.
		 * You cannot use the array index as value for the key attribute.
			key value should be a unique id and stable value (not change across re-render) that represents the element
		 */
		<Draggable draggableId={draggableId} index={index} key={draggableId}>
			{(provided, snapshot) => {
				const itemDragHandleProps = !forceSelect ? provided.dragHandleProps : {};
				const buttonDraggableHandleProps = !forceSelect ? {} : provided.dragHandleProps;

				return (
					<ItemDraggable
						dataCy={dataCy}
						ref={provided.innerRef}
						isDragging={snapshot.isDragging}
						updapdateVerticalPadding={contentHeight <= buttonDraggableHeight}
						{...provided.draggableProps}
						{...itemDragHandleProps}
						forceSelect={forceSelect}
						withActiveBackground={withActiveBackground}
						canBeselected={forceSelect}
						hasIconBefore={Boolean(props.IconBefore)}
						{...props}
					>
						<ItemContent ref={contentRef}>{children}</ItemContent>

						{props.disabled ? (
							<BoxStub {...buttonDraggableHandleProps} />
						) : (
							<ButtonDraggable dataCy={`${dataCy}-button`} {...buttonDraggableHandleProps}>
								<DragDrop />
							</ButtonDraggable>
						)}
					</ItemDraggable>
				);
			}}
		</Draggable>
	);
}
