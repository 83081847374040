import {ComponentPropsWithoutRef, forwardRef, useRef, useState} from 'react';
import {Box} from '../../../box';
import {CSS} from '@stitches/react';
import {useExpandablePanelContext} from '../../context';
import {styled} from '@esgi/ui/theme';
import {animated, config, useSpring} from 'react-spring';
import useResizeObserver from '@react-hook/resize-observer';
import {useComposedRefs} from '@esgi/ui/utils';

type ExpandablePanelContentProps = ComponentPropsWithoutRef<'div'> & {
	/** Mandatory data attribute used by test automation team. */
	dataCy?: string;

	/** Returns a Style interface from a configuration, leveraging the given media and style map. */
	css?: CSS;
};

export const ExpandablePanelContent = forwardRef<HTMLDivElement, ExpandablePanelContentProps>(
	({dataCy, ...props}, forwaredRef) => {
		const [childrenWidth, setChildrenWidth] = useState(0);

		const {open, placeholderWidth} = useExpandablePanelContext();

		const contentInnerRef = useRef<HTMLDivElement>(null);
		const contentRef = useComposedRefs(forwaredRef, contentInnerRef);

		useResizeObserver(contentInnerRef, (entry) => setChildrenWidth(entry.contentRect.width));

		const animatedDivStyles = useSpring({
			width: open ? childrenWidth : placeholderWidth,
			opacity: open ? 1 : 0,
			config: {
				...config.gentle,
				clamp: true,
			},
		});

		return (
			<AnimatedDiv style={animatedDivStyles} open={open}>
				<Content dataCy={dataCy ?? 'expandable-panel-content'} ref={contentRef} {...props} />
			</AnimatedDiv>
		);
	},
);

const AnimatedDiv = styled(animated.div, {
	overflow: 'hidden',
	boxSizing: 'content-box',

	variants: {
		open: {
			true: {
				height: '100%',
				paddingTop: 18,
				paddingBottom: 18,
				paddingInline: 20,
			},
			false: {
				height: 0,
				padding: 0,
			},
		},
	},
});

const Content = styled(Box, {
	maxWidth: 272,
	width: 'max-content',
});
