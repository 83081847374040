import {Button as ButtonRoot} from './components/button';
import {ButtonIcon} from './components/icon';
import {ButtonText} from './components/text';
import {ButtonToggleIcon} from './components/toggle-icon';

export * from './components/base-button';

export const Button = Object.assign(ButtonRoot, {
	Text: ButtonText,
	Icon: ButtonIcon,
	ToggleIcon: ButtonToggleIcon,
});
